<template>
  <div
    class="flex flex-col min-h-full w-full bg-gray-200 border-r border-black"
  >
    <!--   Section for logged in?   -->
    <div
      class="h-16 min-h-14 w-full bg-blue-800 text-white px-4 py-4 flex justify-between items-center"
    >
      <div class="py-1 flex flex-row" v-if="getAuthLoggedIn">
        <div>Logged in as: {{ getAuth.userEmail }}</div>
      </div>
    </div>
    <!--  Section for navigation  -->
    <div class="flex flex-col mx-4">
      <div
        v-bind:class="[
          'flex flex-row p-4 items-center',
          getCurrentRoute === ROUTE_QUEUE ? 'font-bold' : ''
        ]"
        @click="navigateToQueue"
      >
        Bodyprint
      </div>
      <div
        v-bind:class="[
          'flex flex-row p-4 items-center',
          getCurrentRoute === ROUTE_PRODUCTION ? 'font-bold' : ''
        ]"
        @click="navigateToProduction"
      >
        Production
      </div>
    </div>
    <!--  Section for logout  -->
    <div class="border-t mx-4">
      <div v-bind:class="['flex flex-row p-4 items-center']" @click="showLogoutDialog = true">
        Logout
      </div>
    </div>
    <Dialog
      v-if="showLogoutDialog"
      @confirmed="logout"
      @close="showLogoutDialog = false"
      color="red"
      title="Are you sure?"
      message="Are you sure you want to logout?"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ROUTE_BODYPRINT_QUEUE, ROUTE_LOGIN, ROUTE_PRODUCTION } from "@/router";
import Dialog from "@/components/dialog.vue";

export default {
  name: "Sidebar",
  components: { Dialog },
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getCurrentRoute: "application/getCurrentRoute"
    }),
    ...mapActions(["auth/authcheck"])
  },
  data() {
    return {
      showLogoutDialog: false
    };
  },
  created() {
    this.ROUTE_QUEUE = ROUTE_BODYPRINT_QUEUE;
    this.ROUTE_PRODUCTION = ROUTE_PRODUCTION;
  },
  methods: {
    navigateToQueue() {
      this.$router.replace({ name: ROUTE_BODYPRINT_QUEUE });
    },
    navigateToProduction() {
      this.$router.replace({ name: ROUTE_PRODUCTION });
    },
    logout() {
      this.$store.dispatch("auth/authlogout").then(() => {
        this.$store.dispatch("layout/toggleSidebar");
        this.$router.replace({ name: ROUTE_LOGIN });
      });
    }
  }
};
</script>
