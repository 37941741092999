<template>
  <div
    class="bg-white py-4 px-6"
    v-if="instruction.name && instruction.image && instruction.text"
  >
    <p class="text-xl font-semibold text-blue-800">{{ instruction.name }}</p>
    <div class="w-full h-1 bg-gray-400" />
    <div class="w-full flex justify-center mt-2">
      <img
        class="w-auto h-full instruction-image"
        :src="instruction.image"
        alt="Measure instruction image"
      />
      <div class="w-full ml-5">
        <p>{{ instruction.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import controlMixin from "../control-mixin";
import { mapGetters, mapState } from "vuex";

export default {
  name: "MeasureInstructions",
  props: {},
  components: {},
  mixins: [controlMixin],
  data() {
    return {
      instruction: {
        name: null,
        image: null,
        text: null
      }
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  computed: {
    ...mapState({
      newCoordinates: "bodyprint/newCoordinates",
      activeCoordinates: "bodyprint/activeCoordinates"
    }),
    ...mapGetters({
      getMeasureStep: "bodyprint/getMeasureStep",
      getArea: "configuration/getArea",
      getNewCoordinates: "bodyprint/getNewCoordinates"
    })
  },
  methods: {
    getInstructionInformation() {
      const handle = this.getArea.handles.find(
        handle => handle.puppetPointName === this.getNewCoordinates.pointNumber
      );

      var newInstruction = {
        name: null,
        image: null,
        text: null
      };

      if (handle !== undefined) {
        switch (handle.name) {
          // Top head left
          case "measurement_left_handle_top":
            newInstruction.name = "Head";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_top.jpg`);
            newInstruction.text =
              "Move the pointer towards the center of the head.";
            break;
          // Top head back
          case "measurement_back_handle_top":
            newInstruction.name = "Head";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_top.jpg`);
            newInstruction.text =
              "Move the pointer towards the center of the head.";
            break;
          // Bottom feet left
          case "measurement_left_handle_feet_front":
            newInstruction.name = "Feet";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_feet_front.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the front side of your foot.";
            break;
          case "measurement_left_handle_feet_back":
            newInstruction.name = "Feet";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_feet_back_animated.gif`);
            newInstruction.text =
              "Place the pointer against the backside and under the heel of the foot.";
            break;
          // Bottom feet back
          case "measurement_back_handle_feet_left":
            newInstruction.name = "Feet";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_feet_left.jpg`);
            newInstruction.text =
              "Place pointer in the middle under the heel of the left foot.";
            break;
          case "measurement_back_handle_feet_right":
            newInstruction.name = "Feet";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_feet_left.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the middle of the right foot.";
            break;
          // Left head
          case "measurement_left_handle_neck_depth":
            newInstruction.name = "Neck";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_neck_front.jpg`);
            newInstruction.text =
              "Place the pointer just below the Adam's Apple.";
            break;
          case "measurement_left_handle_spine_top":
            newInstruction.name = "Neck";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_neck_back.jpg`);
            newInstruction.text =
              "Place the pointer just above the neck joint.";
            break;
          // Back head
          case "measurement_back_handle_neck_left":
            newInstruction.name = "Neck";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_neck_left.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the left side of the neck.";
            break;
          case "measurement_back_handle_neck_right":
            newInstruction.name = "Neck";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_neck_right.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right side of the neck.";
            break;
          // Back left arm
          case "measurement_back_handle_shoulder_left":
            newInstruction.name = "Left arm";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_shoulder_left_animated.gif`);
            newInstruction.text = "Place the pointer on the left shoulder.";
            break;
          case "measurement_back_handle_elbow_left":
            newInstruction.name = "Left arm";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_elbow_left.jpg`);
            newInstruction.text =
              "Place the pointer at the left side of the elbow.";
            break;
          case "measurement_back_handle_hand_left":
            newInstruction.name = "Left arm";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_hand_left.jpg`);
            newInstruction.text =
              "Place the pointer at the left side of the wrist lines.";
            break;
          // Back right arm
          case "measurement_back_handle_shoulder_right":
            newInstruction.name = "Right arm";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_shoulder_right_animated.gif`);
            newInstruction.text = "Place the pointer on the right shoulder.";
            break;
          case "measurement_back_handle_elbow_right":
            newInstruction.name = "Right arm";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_elbow_right.jpg`);
            newInstruction.text =
              "Place the pointer at the right side of the elbow.";
            break;
          case "measurement_back_handle_hand_right":
            newInstruction.name = "Right arm";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_hand_right.jpg`);
            newInstruction.text =
              "Place the pointer at the right side of the wrist lines.";
            break;
          // Left arm left
          case "measurement_left_handle_shoulders":
            newInstruction.name = "Arms";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_shoulders.jpg`);
            newInstruction.text =
              "Move the pointer horizontally to the center of the shoulder.";
            break;
          case "measurement_left_handle_elbows":
            newInstruction.name = "Arms";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_elbows.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the center of the arm.";
            break;
          case "measurement_left_handle_hands":
            newInstruction.name = "Arms";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_hands.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the center of the arm.";
            break;
          // Back crotch
          case "measurement_back_handle_crotch":
            newInstruction.name = "Crotch";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_crotch.jpg`);
            newInstruction.text = "Place the pointer at the crotch.";
            break;
          // Left ankle
          case "measurement_left_handle_ankles":
            newInstruction.name = "Ankle";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_ankle.jpg`);
            newInstruction.text = "Place the pointer at your ankle bone.";
            break;
          // Left chest
          case "measurement_left_handle_chest_front":
            newInstruction.name = "Chest";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_chest_front.jpg`);
            newInstruction.text =
              "Place the pointer against the frontside of the chest.";
            break;
          case "measurement_left_handle_chest_back":
            newInstruction.name = "Chest";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_chest_back.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) towards the back.";
            break;
          // Back chest
          case "measurement_back_handle_chest_left":
            newInstruction.name = "Chest";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_chest_left.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the left armpit.";
            break;
          case "measurement_back_handle_chest_right":
            newInstruction.name = "Chest";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_chest_right.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right armpit.";
            break;
          // Left waist
          case "measurement_left_handle_waist_front":
            newInstruction.name = "Waist";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_waist_front.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the left side of the body.";
            break;
          case "measurement_left_handle_waist_back":
            newInstruction.name = "Waist";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_waist_back.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right side of the body.";
            break;
          // Back waist
          case "measurement_back_handle_waist_left":
            newInstruction.name = "Waist";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_waist_left.jpg`);
            newInstruction.text =
              "Place the pointer against your left side waist (in general smallest part of body).";
            break;
          case "measurement_back_handle_waist_right":
            newInstruction.name = "Waist";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_waist_right.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right side of your waist.";
            break;
          // Left band
          case "measurement_left_handle_band_front":
            newInstruction.name = "Band";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_band_front_animated.gif`);
            newInstruction.text =
              "Place the pointer against the left side of the waistband.";
            break;
          case "measurement_left_handle_band_back":
            newInstruction.name = "Band";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_band_back_animated.gif`);
            newInstruction.text =
              "Place the pointer against the right side of the waistband.";
            break;
          // Back band
          case "measurement_back_handle_band_left":
            newInstruction.name = "Band";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_band_left.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the left side of the body.";
            break;
          case "measurement_back_handle_band_right":
            newInstruction.name = "Band";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_band_right.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right side of the body.";
            break;
          // Left hips
          case "measurement_left_handle_hips_front":
            newInstruction.name = "Hips";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_hips_front.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the left side of the body.";
            break;
          case "measurement_left_handle_hips_back":
            newInstruction.name = "Hips";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_hips_back.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right side of the body.";
            break;
          // Back hips
          case "measurement_back_handle_hips_left":
            newInstruction.name = "Hips";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_hips_left.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the left side of the body.";
            break;
          case "measurement_back_handle_hips_right":
            newInstruction.name = "Hips";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_hips_right.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right side of the body.";
            break;
          // Left upper thighs
          case "measurement_left_handle_upper_thighs_front":
            newInstruction.name = "Upper leg";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_upper_thigh_front.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the left side of the leg.";
            break;
          case "measurement_left_handle_upper_thighs_back":
            newInstruction.name = "Upper leg";
            newInstruction.image = require(`../../assets/measure/measurement_info_left_upper_thigh_back.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right side of the leg.";
            break;
          // Back upper thighs
          case "measurement_left_handle_upper_thigh_left_left":
            newInstruction.name = "Upper leg";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_upper_thigh_left.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the left side of the leg.";
            break;
          case "measurement_left_handle_upper_thigh_left_right":
            newInstruction.name = "Upper leg";
            newInstruction.image = require(`../../assets/measure/measurement_info_back_upper_thigh_right.jpg`);
            newInstruction.text =
              "Move the pointer (horizontally) to the right side of the leg.";
            break;
          default:
            newInstruction.image = null;
            newInstruction.text = null;
            break;
        }
      }

      this.instruction = newInstruction;
    }
  },
  watch: {
    activeCoordinates: {
      handler() {
        this.getInstructionInformation();
      },
      deep: true
    }
  }
};
</script>

<style>
.instruction-image {
  height: 200px !important;
  border: 2px solid black;
}
</style>
