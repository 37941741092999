var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col min-h-full w-full bg-gray-200 border-r border-black"},[_c('div',{staticClass:"h-16 min-h-14 w-full bg-blue-800 text-white px-4 py-4 flex justify-between items-center"},[(_vm.getAuthLoggedIn)?_c('div',{staticClass:"py-1 flex flex-row"},[_c('div',[_vm._v("Logged in as: "+_vm._s(_vm.getAuth.userEmail))])]):_vm._e()]),_c('div',{staticClass:"flex flex-col mx-4"},[_c('div',{class:[
        'flex flex-row p-4 items-center',
        _vm.getCurrentRoute === _vm.ROUTE_QUEUE ? 'font-bold' : ''
      ],on:{"click":_vm.navigateToQueue}},[_vm._v(" Bodyprint ")]),_c('div',{class:[
        'flex flex-row p-4 items-center',
        _vm.getCurrentRoute === _vm.ROUTE_PRODUCTION ? 'font-bold' : ''
      ],on:{"click":_vm.navigateToProduction}},[_vm._v(" Production ")])]),_c('div',{staticClass:"border-t mx-4"},[_c('div',{class:['flex flex-row p-4 items-center'],on:{"click":function($event){_vm.showLogoutDialog = true}}},[_vm._v(" Logout ")])]),(_vm.showLogoutDialog)?_c('Dialog',{attrs:{"color":"red","title":"Are you sure?","message":"Are you sure you want to logout?"},on:{"confirmed":_vm.logout,"close":function($event){_vm.showLogoutDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }