var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex-col mx-auto p-4 w-full h-full items-center text-blue-800",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"w-3/4"},[_c('div',{staticClass:"w-full flex flex-row justify-center items-center my-12"},[_c('div',{staticClass:"mr-4 flex-col"},[_c('label',{staticClass:"block font-medium text-xl text-gray-700",attrs:{"for":"retailer_code"}},[_vm._v("Retailer Code")]),_c('div',{staticClass:"flex flex-row justify-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"mt-1 p-2 border-2 border-solid border-black rounded-md",attrs:{"id":"retailer_code","type":"text","placeholder":"Retailer code"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing)return;_vm.code=$event.target.value}}})])]),_c('div',{staticClass:"mr-4 flex-col"},[_c('label',{staticClass:"block font-medium text-xl text-gray-700",attrs:{"for":"email_address"}},[_vm._v("Email address")]),_c('div',{staticClass:"flex flex-row justify-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"mt-1 p-2 border-2 border-solid border-black rounded-md",attrs:{"id":"email_address","type":"text","autocomplete":"email","placeholder":"Email address"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})])]),_c('div',[_c('button',{class:{
              'text-xl p-3 m-5 mb-2 text-white': true,
              'bg-blue-800': true
            },on:{"click":function($event){return _vm.searchQueue()}}},[_vm._v(" Search for orders ")]),_c('button',{staticClass:"ml-2",on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v(" Clear ")])])]),_c('div',{staticClass:"flex flex-col w-full items-center"},[_c('p',{staticClass:"text-xl font-bold mt-5"},[_vm._v(" Orders ")]),(_vm.tableLoading)?_c('div',{staticClass:"table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"},[_c('p',{staticClass:"text-lg"},[_vm._v("Loading orders...")])]):(_vm.orders.length > 0)?_c('table',{staticClass:"table-fixed w-full border-solid px-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"},[_vm._m(0),_c('tbody',_vm._l((this.orders),function(order,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(order.user ? order.user.email : "-")+" ")]),_c('td',[_vm._v(_vm._s(order.queue_item.code))]),_c('td',[_vm._v(_vm._s(order.order_number))]),_c('td',[_c('div',{staticClass:"flex"},[_c('button',{class:[
                      'w-24 text-md p-2 m-2 text-white ',
                      _vm.loading ? 'bg-gray-600' : 'bg-blue-800'
                    ],attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.requestOrderToView(order.id)}}},[_vm._v(" View ")])])])])}),0)]):_c('div',[(_vm.tableLoading === false)?_c('p',[_vm._v(" No orders found ")]):_c('i',{staticClass:"fas fa-circle-notch fa-spin fa-5x mt-5"})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"w-6/12"},[_vm._v("User")]),_c('th',{staticClass:"w-3/12"},[_vm._v("Retailer code")]),_c('th',{staticClass:"w-3/12"},[_vm._v("Order number")]),_c('th')])])
}]

export { render, staticRenderFns }