import { mapGetters } from "vuex";

export default {
  namespaced: true,
  state: {
    loading: true,
    imageSide: null,
    imageBack: null,
    imageScaling: 0.6,
    windowHeight: window.innerHeight - 120,
    imageHeight: 1000,
    imageWidth: 750,
    orders: [],
    order: null
  },
  computed: {
    ...mapGetters({
      getPointsByArea: "configuration/getPointsByArea",
      getGuide: "bodyprint/getGuide",
      getArea: "configuration/getArea",
      getAreas: "configuration/getAreas",
      getPoint: "configuration/getPoint",
      getNewCoordinates: "bodyprint/getNewCoordinates"
    })
  },
  getters: {
    getImage: state => projection => {
      if (projection === "left") {
        return state.imageSide;
      } else {
        return state.imageBack;
      }
    },
    getOrder: state => {
      return state.order;
    },
    getOrders: state => {
      return state.orders;
    },
    getImageSide: state => {
      return state.imageSide;
    },
    getImageBack: state => {
      return state.imageBack;
    },
    getPictureScale: state => {
      return (state.windowHeight * state.imageScaling) / state.imageHeight;
    },
    getPictureWidth: (state, getters) => {
      return state.imageWidth * getters.getPictureScale;
    },
    getPictureHeight: state => {
      return state.windowHeight * state.imageScaling;
    },
    getCropBack: state => {
      return state.order.queue_item.bodyprint.guides.find(
        guide => guide.projection === "back"
      );
    },
    getCropSide: state => {
      return state.order.queue_item.bodyprint.guides.find(
        guide => guide.projection === "left"
      );
    }
  },
  mutations: {
    setWindowHeight(state, payload) {
      // change 84 to desired
      let newHeight = payload.height - 84;
      let newScale = newHeight / state.imageHeight;
      let newWidth = state.imageWidth * newScale;
      if (newWidth < payload.width / 3) {
        state.windowHeight = newHeight;
      }
    },
    setZoom(state, payload) {
      state.zoom = payload;
    },
    // setModifiedPoint(state, payload) {
    //   console.log('modified point');
    //   console.log(state);
    //   console.log(payload);
    // },
    setMeasureImage(state, payload) {
      state.measureImage = payload;
    },
    SET_IMAGE_WIDTH(state, width) {
      state.imageWidth = width;
    },
    SET_IMAGE_HEIGHT(state, height) {
      state.imageHeight = height;
    },
    setImages(state, images) {
      state.imageBack = images.back.data;
      state.imageSide = images.left.data;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setCrop(state, payload) {
      payload[0].width = payload[0].x2 - payload[0].x;
      payload[0].height = payload[0].y2 - payload[0].y;
      payload[0].growthFactor =
        (state.windowHeight * state.imageScaling) /
        (payload[0].height * (state.windowHeight * state.imageScaling));
      let guide = state.order.queue_item.bodyprint.guides.find(
        guide => guide.projection === payload[1]
      );
      guide.crop = payload[0];
    }
  },
  actions: {
    async setOrder({ commit }, order) {
      //logic for set to normal
      commit("setOrder", order);
    },
    prepareOrderView({ dispatch }) {
      dispatch("calculateBodyprint");
    },
    calculateBodyprint({ commit, state, getters }) {
      const bodyprint = JSON.parse(
        JSON.stringify(state.order.queue_item.bodyprint)
      );
      var img = new Image();
      img.src = state.imageSide;

      var imgBack = new Image();
      var imgSide = new Image();

      imgBack.src = state.imageBack;
      imgSide.src = state.imageSide;

      setTimeout(() => {
        commit("SET_IMAGE_WIDTH", img.width);
        commit("SET_IMAGE_HEIGHT", img.height);

        let growthFactorSide =
          imgSide.height /
          (state.order.queue_item.bodyprint.guides.find(
            x => x.projection === "left"
          ).crop.height *
            imgSide.height);

        let growthFactorBack =
          imgBack.height /
          (state.order.queue_item.bodyprint.guides.find(
            x => x.projection === "back"
          ).crop.height *
            imgBack.height);

        let cropWidthSide = bodyprint.guides[0].crop.width;
        let cropWidthBack = bodyprint.guides[1].crop.width;
        var pointsProcessed = 0;
        if (bodyprint.points.length === 0) {
          commit("setLoading", false);
        }
        bodyprint.points.forEach(function(part) {
          pointsProcessed++;
          part.x =
            (part.x /
              (getters.getPictureWidth * growthFactorBack * cropWidthSide)) *
            ((state.windowHeight * state.imageScaling) / 1000);
          part.z =
            (part.z /
              (getters.getPictureWidth * growthFactorSide * cropWidthBack)) *
            ((state.windowHeight * state.imageScaling) / 1000);
          part.y = part.y / 1000;

          if (bodyprint.points.length === pointsProcessed) {
            state.order.queue_item.bodyprint = bodyprint;
            setTimeout(() => {
              commit("setLoading", false);
            }, 1000);
          }
          if (cropWidthSide) {
            bodyprint.guides[0].crop.x2 =
              bodyprint.guides[0].crop.x + cropWidthSide;
            bodyprint.guides[0].crop.y2 =
              bodyprint.guides[0].crop.y + cropWidthSide;
            bodyprint.guides[0].crop.growthFactor =
              (state.windowHeight * state.imageScaling) /
              (bodyprint.guides[0].crop.height *
                (state.windowHeight * state.imageScaling));
            bodyprint.guides[1].crop.x2 =
              bodyprint.guides[1].crop.x + cropWidthBack;
            bodyprint.guides[1].crop.y2 =
              bodyprint.guides[1].crop.y + cropWidthBack;
            bodyprint.guides[1].crop.growthFactor =
              (state.windowHeight * state.imageScaling) /
              (bodyprint.guides[1].crop.height *
                (state.windowHeight * state.imageScaling));
          }
        });
      }, 1000);
    },
    getOrdersForRetailer({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`retailer_order/request-all-for-retailer`, payload)
        .then(response => {
          const orders = response.data;
          commit("setOrders", orders);
        });
    },
    requestOrderToView({ commit, rootGetters }, orderId) {
      return rootGetters["network/getClient"]
        .get(`retailer_order/${orderId}`)
        .then(response => {
          var order = response.data;
          commit("setOrder", order);
        });
    },
    updateFitAdjustments({ commit, rootGetters }, payload) {
      return rootGetters["network/getClient"]
        .post(`retailer_order/${payload.id}/fit-adjustments`, payload)
        .then(response => {
          var order = response.data;
          commit("setOrder", order);
        });
    },
    getImages({ commit, rootGetters }, id) {
      return rootGetters["network/getClient"]
        .get(`retailer_order/${id}/images`)
        .then(response => commit("setImages", response.data));
    }
  }
};
