import { ROUTE_PRODUCTION, ROUTE_BODYPRINT_QUEUE } from "@/router";

export default {
  namespaced: true,
  state: {
    currentRoute: ""
  },
  getters: {
    getCurrentRoute: state => {
      return state.currentRoute;
    },
    canShowMenu: state => {
      const availableRoutes = [ROUTE_BODYPRINT_QUEUE, ROUTE_PRODUCTION];
      return availableRoutes.includes(state.currentRoute);
    }
  },
  actions: {
    updateCurrentRoute({ commit }, newRoute) {
      commit("setCurrentRoute", newRoute);
    }
  },
  mutations: {
    setCurrentRoute(state, payload) {
      state.currentRoute = payload;
    }
  }
};
