<template>
  <div class="flex">
    <div
      class="flex flex-col mx-auto p-4 w-full h-full items-center text-blue-800"
      style="text-align: center"
    >
      <!--   Show loading state   -->
      <div class="w-3/4">
        <div class="w-full flex flex-row justify-center items-center my-12">
          <div class="mr-4 flex-col">
            <label
              for="retailer_code"
              class="block font-medium text-xl text-gray-700"
              >Retailer Code</label
            >
            <div class="flex flex-row justify-center">
              <input
                id="retailer_code"
                type="text"
                v-model="code"
                placeholder="Retailer code"
                class="
                        mt-1
                        p-2
                        border-2 border-solid border-black
                        rounded-md
                        "
              />
            </div>
          </div>
          <div class="mr-4 flex-col">
            <label
              for="email_address"
              class="block font-medium text-xl text-gray-700"
              >Email address</label
            >
            <div class="flex flex-row justify-center">
              <input
                id="email_address"
                type="text"
                v-model="email"
                autocomplete="email"
                placeholder="Email address"
                class="
                        mt-1
                        p-2
                        border-2 border-solid border-black
                        rounded-md
                        "
              />
            </div>
          </div>
          <div>
            <button
              @click="searchQueue()"
              :class="{
                'text-xl p-3 m-5 mb-2 text-white': true,
                'bg-blue-800': true
              }"
            >
              Search for orders
            </button>
            <button class="ml-2" @click="clearSearch()">
              Clear
            </button>
          </div>
        </div>

        <div class="flex flex-col w-full items-center">
          <p class="text-xl font-bold mt-5">
            Orders
          </p>
          <div
            class="table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"
            v-if="tableLoading"
          >
            <p class="text-lg">Loading orders...</p>
          </div>
          <table
            class="table-fixed w-full border-solid px-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"
            v-else-if="orders.length > 0"
          >
            <thead>
              <tr>
                <th class="w-6/12">User</th>
                <th class="w-3/12">Retailer code</th>
                <th class="w-3/12">Order number</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order, index) in this.orders" :key="index">
                <td>
                  {{ order.user ? order.user.email : "-" }}
                </td>
                <td>{{ order.queue_item.code }}</td>
                <td>{{ order.order_number }}</td>
                <td>
                  <div class="flex">
                    <button
                      @click="requestOrderToView(order.id)"
                      v-bind:class="[
                        'w-24 text-md p-2 m-2 text-white ',
                        loading ? 'bg-gray-600' : 'bg-blue-800'
                      ]"
                      :disabled="loading"
                    >
                      View
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <p v-if="tableLoading === false">
              No orders found
            </p>
            <i v-else class="fas fa-circle-notch fa-spin fa-5x mt-5"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import { ROUTE_PRODUCTION_ORDER_EDIT } from "@/router";

export default {
  name: "ProductionListView",
  components: {},
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getSidebarOpen: "layout/getSidebarOpen"
    }),
    ...mapActions(["auth/authcheck", "layout/toggleSidebar"])
  },
  data() {
    return {
      dataProvided: false,
      loading: false,
      tableLoading: false,
      loaded: false,
      failed: false,
      email: null,
      code: null,
      emailFilled: false,
      codeFilled: false,
      errorMessage: null,
      measurementsLoading: false,
      showMeasurementsModal: false,
      measurementsBodyprint: null,
      orders: []
    };
  },
  methods: {
    searchQueue() {
      this.orders = [];
      this.tableLoading = true;

      this.$store
        .dispatch("retailerOrders/getOrdersForRetailer", {
          email: this.email,
          code: this.code
        })
        .then(() => {
          this.orders = this.sortedOrders();
          this.tableLoading = false;
          this.errorMessage = null;
        })
        .catch(() => {
          this.tableLoading = false;
          this.errorMessage = "Could not find orders.";
        });
    },
    sortedOrders() {
      return this.$store.getters["retailerOrders/getOrders"].sort((a, b) => {
        const momentA = moment(a.created_at, "DD-MM-YYYY hh:mm:ss");
        const momentB = moment(b.created_at, "DD-MM-YYYY hh:mm:ss");

        if (momentA.isBefore(momentB)) {
          return 1;
        } else if (momentA.isAfter(momentB)) {
          return -1;
        } else {
          return 0;
        }
      });
    },
    clearSearch() {
      this.email = "";
      this.code = "";
      this.searchQueue();
    },
    requestOrderToView(id) {
      if (this.getSidebarOpen) {
        this.$store.dispatch("layout/toggleSidebar");
      }
      this.loading = true;
      this.$store.dispatch("retailerOrders/requestOrderToView", id).then(() => {
        var order = this.$store.getters["retailerOrders/getOrder"];
        this.$store
          .dispatch("retailerOrders/getImages", order.id)
          .then(() => {
            this.$store.dispatch("retailerOrders/prepareOrderView").then(() => {
              this.loaded = true;
              this.loading = false;
              this.$router.push({
                name: ROUTE_PRODUCTION_ORDER_EDIT,
                params: { order_id: order.id }
              });
            });
          })
          .catch(() => {
            // TODO: Show error message?
            this.loading = false;
            this.failed = true;
          });
      });
    }
  },
  created() {
    this.searchQueue();
  },
  watch: {
    getAuthLoggedIn() {
      if (this.getAuthLoggedIn) {
        this.loadBodyPrint();
      }
    },
    email(newEmail) {
      this.emailFilled = newEmail !== "";
    },
    code(newCode) {
      this.codeFilled = newCode !== "";
    }
  }
};
</script>
