<template>
  <div class="flex flex-col text-blue-800">
    <div class="grid grid-cols-12">
      <div class="flex flex-col col-span-7">
        <div class="grid grid-cols-12 px-12 pt-12 pb-6">
          <!--    First row : user & brand     -->
          <p class="col-span-2 font-bold">User</p>
          <p class="col-span-4">{{ getOrder.user.email }}</p>
          <p class="col-span-2 font-bold">Brand</p>
          <p class="col-span-4">{{ getOrder.brand.name }}</p>
          <!--    Second row : retailer & product     -->
          <p class="col-span-2 font-bold">Retailer</p>
          <p class="col-span-4">{{ getOrder.retailer.name }}</p>
          <p class="col-span-2 font-bold">Product</p>
          <p class="col-span-4">{{ getOrder.product.name }}</p>
          <!--    Third row : order number & style     -->
          <p class="col-span-2 font-bold">Order number</p>
          <p class="col-span-4">{{ getOrder.order_number }}</p>
          <p class="col-span-2 font-bold">Style</p>
          <p class="col-span-4">{{ getOrder.style.name }}</p>
          <!--    Fourth row : order date & fit     -->
          <p class="col-span-2 font-bold">Order date</p>
          <p class="col-span-4">{{ getCreatedAtDate() }}</p>
          <p class="col-span-2 font-bold">Fit</p>
          <p class="col-span-4">
            {{
              getOrder.style.fit_specification.specification_items
                ? getOrder.style.fit_specification.specification_items[0]
                    .size_item_serie_name
                : "-"
            }}
          </p>
          <!--    Fifth row : order status & tailoring     -->
          <p class="col-span-2 font-bold">Order status</p>
          <p class="col-span-4">{{ getOrder.status }}</p>
          <p class="col-span-2 font-bold">Tailoring</p>
          <p class="col-span-4">{{ getOrder.tailoring }}</p>
        </div>
        <div class="flex flex-grow p-4">
          <div class="flex flex-col w-full items-center">
            <div
              class="table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 adjustments-table overflow-y-auto"
              v-if="adjustmentsLoading"
            >
              <p class="text-lg">Loading adjustments...</p>
            </div>
            <table
              class="table-fixed w-full border-solid border-gray-200 border-2 mt-3 adjustments-table overflow-y-auto"
              v-else-if="adjustments.length > 0"
            >
              <thead>
                <tr>
                  <th class="w-2/12">Size Item</th>
                  <th class="w-2/12">Body measurements</th>
                  <th class="w-2/12">Fit specifications</th>
                  <th class="w-2/12">Product measurements</th>
                  <th class="w-2/12">Fit adjustments</th>
                  <th class="w-2/12">Production order</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(adjustment, index) in this.adjustments"
                  :key="index"
                >
                  <td>{{ adjustment.size_item }}</td>
                  <td>{{ adjustment.body_measurement }}</td>
                  <td>{{ adjustment.fit_specification }}</td>
                  <td></td>
                  <td>
                    <input
                      :id="`fit_adjustment_${index}`"
                      type="number"
                      v-model="fitAdjustments[index].value"
                      step="0.1"
                      class="w-20 mt-1 py-2 pl-2 text-center border-2 border-solid border-black rounded-md"
                    />
                  </td>
                  <td>{{ getProductionOrderValue(index) }}</td>
                </tr>
              </tbody>
            </table>
            <div v-else>
              <p v-if="adjustmentsLoading === false && adjustmentsError === ''">
                No adjustments found
              </p>
              <p
                v-else-if="
                  adjustmentsLoading === false && adjustmentsError !== ''
                "
              >
                {{ adjustmentsError }}
              </p>
              <i v-else class="fas fa-circle-notch fa-spin fa-5x mt-5"></i>
            </div>
          </div>
        </div>
        <div class="col-span-4 bg-gray-200 m-4 p-4">
          <div class="flex flex-row justify-center">
            <button
              class="button py-2 px-8 w-40 bg-red-700 text-white mx-2"
              @click="showReturnDialog = true"
            >
              Return
            </button>
            <button
              class="button py-2 px-8 w-40 bg-blue-800 text-white mx-2"
              @click="showSaveDialog = true"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div class="flex w-full col-span-5 bg-gray-200">
        <div class="grid grid-cols-6 p-6 w-full">
          <div class="flex flex-col col-span-3 justify-center items-center">
            <p class="font-bold text-2xl pb-2">
              Back
            </p>
            <bodyprint-view
              :image-source="this.getImageBack"
              :is-active="true"
              side="back"
              :flipped="false"
              :picture-height="getPictureHeight"
              :picture-width="getPictureWidth"
              :back-guide="getCropBack"
              :side-guide="getCropSide"
              :show-active-line="false"
            />
          </div>
          <div class="flex flex-col col-span-3 justify-center items-center">
            <p class="font-bold text-2xl pb-2">
              Side
            </p>
            <bodyprint-view
              :image-source="this.getImageSide"
              :is-active="true"
              side="side"
              :flipped="false"
              :picture-height="getPictureHeight"
              :picture-width="getPictureWidth"
              :back-guide="getCropBack"
              :side-guide="getCropSide"
              :show-active-line="false"
            />
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-if="showReturnDialog"
      @confirmed="toListScreen"
      @close="showReturnDialog = false"
      color="red"
      title="Are you sure?"
      message="Are you sure you want to close the view of this order?"
    />
    <Dialog
      v-if="showSaveDialog"
      @confirmed="saveOrder"
      @close="showSaveDialog = false"
      title="Are you sure?"
      message="Are you sure you want to save the changes in the order?"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROUTE_PRODUCTION } from "@/router";
import moment from "moment";
import BodyprintView from "@/components/bodyprint/bodyprint-view.vue";
import Dialog from "@/components/dialog.vue";

export default {
  name: "ProductionOrderView",
  components: { Dialog, BodyprintView },
  created() {
    if (!this.getOrder) {
      this.$router.replace({ name: ROUTE_PRODUCTION });
      return;
    }

    this.prepareView();

    window.addEventListener("resize", this.setImageWindowHeight);
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
    window.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getSidebarOpen: "layout/getSidebarOpen",
      getOrder: "retailerOrders/getOrder",
      getImageSide: "retailerOrders/getImageSide",
      getImageBack: "retailerOrders/getImageBack",
      getPictureHeight: "retailerOrders/getPictureHeight",
      getPictureWidth: "retailerOrders/getPictureWidth",
      getCropBack: "retailerOrders/getCropBack",
      getCropSide: "retailerOrders/getCropSide"
    })
  },
  data() {
    return {
      showSaveDialog: false,
      showReturnDialog: false,
      adjustmentsError: "",
      adjustmentsLoading: true,
      adjustments: [],
      fitAdjustments: []
    };
  },
  methods: {
    ...mapMutations({
      setWindowHeight: "retailerOrders/setWindowHeight",
      setCrop: "retailerOrders/setCrop"
    }),
    ...mapActions(["auth/authcheck", "layout/toggleSidebar"]),
    prepareView() {
      if (!this.getOrder) return;

      this.setImageWindowHeight();

      if (this.getOrder.style.size_item_templates.length === 0) {
        this.adjustmentsLoading = false;
      } else {
        for (const item of this.getOrder.style.size_item_templates) {
          let measurement = this.getOrder.queue_item.bodyprint_measurements.find(
            m => m.name === item.code
          );

          if (!measurement) {
            this.adjustmentsError = "Incomplete measurement data";
            this.adjustmentsLoading = false;
            break;
          }

          let fitAdjustment = this.getOrder.fit_adjustments.find(
            f => f.size_item_code === item.code
          );

          if (!fitAdjustment) {
            this.adjustmentsError = "Incomplete fit adjustment data";
            this.adjustmentsLoading = false;
            break;
          }

          if (!this.getOrder.style.fit_specification.specification_items) {
            this.adjustmentsError = "Incomplete fit specification data";
            this.adjustmentsLoading = false;
            break;
          }

          let fitSpecificationItem = this.getOrder.style.fit_specification.specification_items[0].specs.find(
            m => m.code === item.code
          );

          if (!fitSpecificationItem) {
            this.adjustmentsError = "Incomplete fit specification data";
            this.adjustmentsLoading = false;
            break;
          }

          this.adjustments.push({
            code: item.code,
            size_item: item.label,
            body_measurement: measurement.value.toFixed(1),
            fit_specification: fitSpecificationItem.amount_of_space.toFixed(1),
            product_measurement: ""
          });

          this.fitAdjustments.push(fitAdjustment);
        }

        this.adjustmentsLoading = false;
      }
    },
    setImageWindowHeight() {
      let payload = {
        height: window.innerHeight,
        width: window.innerWidth
      };
      this.setWindowHeight(payload);
      this.setProjectionCrop(
        this.getOrder.queue_item.bodyprint.guides[0].crop,
        "back"
      );
      this.setProjectionCrop(
        this.getOrder.queue_item.bodyprint.guides[1].crop,
        "left"
      );
    },
    setProjectionCrop(crop, projection) {
      let updatedCrop = {
        x: crop.x,
        x2: crop.x + crop.width,
        y: crop.y,
        y2: crop.y + crop.height,
        width: crop.width,
        height: crop.height
      };
      this.setCrop([updatedCrop, projection]);
    },
    toListScreen() {
      this.$router.replace({
        name: ROUTE_PRODUCTION
      });
    },
    saveOrder() {
      const updatedFitAdjustments = [];
      this.fitAdjustments.forEach(item => {
        updatedFitAdjustments.push(item);
      });

      this.$store
        .dispatch("retailerOrders/updateFitAdjustments", {
          id: this.getOrder.id,
          adjustments: updatedFitAdjustments
        })
        .then(() => {
          console.log("Updated fit adjustments");
          this.toListScreen();
        });
    },
    getCreatedAtDate() {
      return moment(this.getOrder.created_at, "DD-MM-YYYY hh:mm:ss").format(
        "YYYY-MM-DD"
      );
    },
    getProductionOrderValue(index) {
      const fitAdjustment = parseFloat(this.fitAdjustments[index].value) || 0;
      const adjustment = this.adjustments[index];

      // TODO: Check what to do for half (chest, hips, etc)

      return (
        parseFloat(adjustment.body_measurement) +
        parseFloat(adjustment.fit_specification) +
        fitAdjustment
      );
    }
  },
  watch: {}
};
</script>
