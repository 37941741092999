export default {
  namespaced: true,
  state: {
    sidebarOpen: false,
  },
  getters: {
    getSidebarOpen: state => {
      return state.sidebarOpen;
    }
  },
  actions: {
    toggleSidebar({ commit }) {
      commit("toggleSidebar");
    }
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    }
  }
};
