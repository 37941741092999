<template>
  <div class="w-full flex flex-wrap mb-4 px-32">
    <div class="w-1/3"></div>
    <button
      class="w-1/3 p-6 text-center border border-gray-500 bg-white shadow-sm focus:shadow-inner"
      @click="control('top')"
    >
      ↑
    </button>
    <div class="w-1/3"></div>
    <button
      class="w-1/3 p-6 text-center border border-gray-500 bg-white shadow-sm"
      @click="control('left')"
    >
      ←
    </button>
    <button
      class="w-1/3 p-6 text-center border border-gray-500 bg-white shadow-sm"
      @click="control('bottom')"
    >
      ↓
    </button>
    <button
      class="w-1/3 p-6 text-center border border-gray-500 bg-white shadow-sm"
      @click="control('right')"
    >
      →
    </button>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import controlMixin from "./control-mixin";

export default {
  mixins: [controlMixin],
  computed: {
    ...mapGetters({
      getCurrentStep: "bodyprint/getCurrentStep",
      getArea: "configuration/getArea",
      getBodyPrint: "bodyprint/getBodyPrint",
      getMeasureStep: "bodyprint/getMeasureStep",
      getPoint: "configuration/getPoint",
      getNewCoordinates: "bodyprint/getNewCoordinates"
    })
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  methods: {
    ...mapMutations({
      updateConstraints: "bodyprint/updateConstraints",
      setActiveCoordinates: "bodyprint/setActiveCoordinates",
      setNewCropCoordinates: "bodyprint/setNewCropCoordinates",
      setNewMeasureCoordinates: "bodyprint/setNewMeasureCoordinates"
    }),
    handleKeydown(e) {
      switch (e.code) {
        case "ArrowRight":
          e.preventDefault();
          this.control("right");
          break;
        case "ArrowLeft":
          this.control("left");
          e.preventDefault();
          break;
        case "ArrowUp":
          this.control("top");
          e.preventDefault();
          break;
        case "ArrowDown":
          this.control("bottom");
          e.preventDefault();
          break;
      }
    },
    control(direction) {
      switch (this.getCurrentStep) {
        case "review": //delete this if the step review has been configured
          this.setNewCropCoordinates(direction);
          break;
        case "crop":
          this.setNewCropCoordinates(direction);
          break;
        case "measure":
          this.setNewMeasureCoordinates(direction);
          var payload = {
            projection: this.getArea.projection,
            step: this.getMeasureStep
          };
          this.updateConstraints(payload);
          break;
      }
    }
  }
};
</script>
