var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex mx-auto p-4 w-full h-full justify-center"},[_c('div',{staticClass:"flex w-full h-full justify-center text-blue-800 my-12",staticStyle:{"text-align":"center"}},[(_vm.dataProvided)?_c('div',[(!_vm.failed)?_c('i',{staticClass:"fas fa-circle-notch fa-spin fa-5x"}):_vm._e(),(!_vm.failed)?_c('div',{staticClass:"text-xl mt-4"},[_vm._v("Loading bodyprint")]):_vm._e(),(_vm.failed)?_c('div',{staticClass:"text-xl"},[_vm._v("Failed to load bodyprint")]):_vm._e()]):_c('div',{staticClass:"w-3/4"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('div',{staticClass:"flex flex-row justify-evenly mb-2"},[(_vm.getAuth.bodyprintQueue === true)?_c('div',{staticClass:"w-1/3"},[_c('p',{staticClass:"text-xl font-bold mt-5"},[_vm._v(" Get next bodyprint in queue ")]),_c('button',{staticClass:"text-xl p-3 m-2 bg-blue-800 text-white",on:{"click":function($event){return _vm.getQueueItem()}}},[_vm._v(" Get queue item ")])]):_vm._e(),(
                _vm.getAuth.bodyprintQueue === true &&
                  _vm.getAuth.bodyprintSearch === true
              )?_c('div',{staticClass:"m-10"},[_c('span',[_vm._v(" Or ")])]):_vm._e(),_c('div',{staticClass:"grid grid-cols-12 w-1/3"},[_c('p',{staticClass:"col-span-12 text-xl font-bold mt-5"},[_vm._v(" Get bodyprints of selected user ")]),_c('div',{staticClass:"col-span-12 mt-3 flex-col"},[_c('label',{staticClass:"block font-medium text-xl text-gray-700",attrs:{"for":"email_address"}},[_vm._v("Email address")]),_c('div',{staticClass:"flex flex-row justify-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"mt-1 p-2 border-2 border-solid border-black rounded-md",attrs:{"id":"email_address","type":"text","autocomplete":"email","placeholder":"Email address"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('button',{staticClass:"ml-2",on:{"click":function($event){return _vm.clearEmail()}}},[_vm._v(" Clear ")])])]),_c('div',{staticClass:"col-span-12"},[_c('button',{class:{
                    'text-xl p-3 m-5 mb-2 text-white': true,
                    'bg-blue-800': _vm.emailFilled,
                    'bg-gray-600': !_vm.emailFilled
                  },attrs:{"disabled":!_vm.emailFilled},on:{"click":function($event){return _vm.searchQueue()}}},[_vm._v(" Search for bodyprints ")])]),_c('p',{staticClass:"col-span-12 text-red-500"},[_vm._v(_vm._s(this.errorMessage))])])]),(
              _vm.getAuth.bodyprintSearch === true ||
                _vm.getAuth.bodyprintList === true
            )?_c('div',{staticClass:"flex flex-row w-full justify-center"},[_c('div',{staticClass:"w-full"},[_c('p',{staticClass:"text-xl font-bold mt-5"},[_vm._v(" Bodyprints ")]),(_vm.tableLoading)?_c('div',{staticClass:"table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"},[_c('p',{staticClass:"text-lg"},[_vm._v("Loading bodyprints...")])]):(_vm.bodyprints.length > 0)?_c('table',{staticClass:"table-fixed w-full border-solid px-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"},[_vm._m(0),_c('tbody',_vm._l((this.bodyprints),function(bodyprint,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(bodyprint.user ? bodyprint.user.email : "-")+" ")]),_c('td',[_vm._v(_vm._s(bodyprint.created_at))]),_c('td',[_vm._v(_vm._s(bodyprint.last_updated_at))]),_c('td',[_vm._v(_vm._s(bodyprint.last_editor))]),_c('td',[_vm._v(_vm._s(bodyprint.retailer_code))]),_c('td',[_vm._v(_vm._s(bodyprint.status))]),_c('td',[_vm._v(" "+_vm._s(bodyprint.user ? bodyprint.user.permission : "-")+" ")]),_c('td',[_c('div',{staticClass:"flex"},[_c('button',{staticClass:"text-md p-2 m-2 text-white bg-blue-800",on:{"click":function($event){return _vm.requestBodyprintToView(bodyprint.id)}}},[_vm._v(" View ")]),_c('button',{class:{
                            'text-md p-2 m-2 text-white': true,
                            'bg-blue-800': bodyprint.status === 'COMPLETED',
                            'bg-gray-600': bodyprint.status !== 'COMPLETED'
                          },attrs:{"disabled":bodyprint.status !== 'COMPLETED'},on:{"click":function($event){return _vm.requestBodyprintSizes(bodyprint.id)}}},[_vm._v(" Sizes ")]),_c('button',{class:{
                            'text-md p-2 m-2 text-white': true,
                            'bg-blue-800': bodyprint.editable,
                            'bg-gray-600': !bodyprint.editable
                          },attrs:{"disabled":!bodyprint.editable},on:{"click":function($event){return _vm.requestBodyprint(bodyprint.id)}}},[_vm._v(" Edit ")])])])])}),0)]):_c('div',[(_vm.tableLoading === false)?_c('p',[_vm._v(" No bodyprints found ")]):_c('i',{staticClass:"fas fa-circle-notch fa-spin fa-5x mt-5"})])])]):_vm._e()]),(!_vm.failed && _vm.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin fa-5x"}):_vm._e(),(!_vm.failed && _vm.loading)?_c('div',{staticClass:"text-xl mt-4"},[_vm._v(" Loading bodyprint ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.failed && !_vm.getQueueEmpty),expression:"failed && !getQueueEmpty"}],staticClass:"flex flex-col gap-2"},[_c('p',{staticClass:"text-xl font-bold"},[_vm._v("Failed to load bodyprint")]),_c('button',{on:{"click":function($event){return _vm.removeFromQueue()}}},[_vm._v(" Reject bodyprint ")]),_c('button',{on:{"click":_vm.backFromEmpty}},[_vm._v(" Return ")])]),(_vm.failed && _vm.getQueueEmpty)?_c('div',{staticClass:"flex flex-col gap-2 text-xl"},[_c('p',[_vm._v("Queue is empty")]),_c('button',{on:{"click":_vm.backFromEmpty}},[_vm._v("Back")])]):_vm._e()])])]),_c('measurements-modal',{attrs:{"loading":_vm.measurementsLoading,"showModal":_vm.showMeasurementsModal,"bodyprint-id":_vm.measurementsBodyprint,"onClose":() => (_vm.showMeasurementsModal = false)}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"w-3/12"},[_vm._v("User")]),_c('th',{staticClass:"w-2/12"},[_vm._v("Created at")]),_c('th',{staticClass:"w-2/12"},[_vm._v("Last updated at")]),_c('th',{staticClass:"w-3/12"},[_vm._v("Last editor")]),_c('th',{staticClass:"w-1/12"},[_vm._v("Retailer code")]),_c('th',{staticClass:"w-1/12"},[_vm._v("Status")]),_c('th',{staticClass:"w-1/12"},[_vm._v("User permission")]),_c('th')])])
}]

export { render, staticRenderFns }